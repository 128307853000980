:root {
  --black: #1a1a1a;
  --white: #ffffff;
  --accent: #580b0b;
  --text: rgb(107, 107, 107);
  --gap: 20px;
  --grey: #f6f6f6;
  --max-width: 1200px;
  --trans: all 0.4s ease-in-out;
  --shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  --rad: 10px;
}

*::before,
*::after,
* {
  box-sizing: border-box;
  // border: 1px solid red;

  margin: 0px;
  font-family: "Poppins", sans-serif;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
// @import url('https://fonts.googleapis.com/css2?family=Alkatra:wght@400..700&display=swap');

// font-family: 'Poppins', sans-serif;
// font-family: "Alkatra", system-ui;
// font-optical-sizing: auto;
// font-weight: 400;
// font-style: normal;

body {
  width: 100%;
  overflow-x: hidden;
  font-weight: 700;

  &::-webkit-scrollbar-thumb {
    background-color: var(--accent) ;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--scrollbar-track-color);
  }

  &::-webkit-scrollbar {
    width: 3px;
  }
}

html {
  scroll-behavior: smooth;
}

.heading,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  font-style: normal;
  color: var(--accent);
}

p {
  text-align: justify;
  font-size: 1rem;
  line-height: 28px;
  letter-spacing: 1px;
  word-spacing: 2px;
  font-weight: 300;
  color: var(--text);
}

@media only screen and (max-width: 1000px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-align: center;
    line-height: 100% !important;
  }
}

.btn-active {
  cursor: pointer;
  border: 3px solid var(--accent);
  background: var(--accent);
  color: var(--white) !important;
  font-size: 18px;
  padding: 5px 20px;
  border-radius: 200px;
  text-decoration: none;
  transition: var(--trans);
  width: fit-content;

  // &:hover {
  //   background: transparent;
  //   color: var(--accent) !important;
  // }
}
.btn-active2 {
  cursor: pointer;
  border: 3px solid var(--accent);
  background: var(--accent);
  color: var(--white) !important;
  font-size: 18px;
  padding: 5px 20px;
  border-radius: 200px;
  text-decoration: none;
  transition: var(--trans);
  width: fit-content;
}
@media (max-width : 650px) 
{
  .btn-active2 {
    cursor: pointer;
    border: 3px solid var(--accent);
    background: var(--accent);
    color: var(--white) !important;
    font-size: 14px !important;
    // padding: 5px 15px;

    border-radius: 200px;
    text-decoration: none;
    transition: var(--trans);
    min-width: 200px;
  display: inline !important;
    // &:hover {
    //   background: transparent;
    //   color: var(--accent) !important;
    // }
  }
}
.btn-unactive {
  cursor: pointer;
  border: 1px solid var(--accent) !important;
  // background: var(--accent);
  color: var(--accent) !important;
  font-size: 18px;
  padding: 5px 20px;
  border-radius: 200px;
  text-decoration: none;
  transition: var(--trans);
  width: fit-content;
}

.bg-img-cover {
  background-position: center center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.bg-img-contain {
  background-position: center center !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
}

.parent {
  width: 100vw;

  .cont {
    max-width: var(--max-width);
    margin: auto;
    padding: 0px 10px;
    height: 100%;
    // background: rgba(102, 51, 153, 0.073);
  }
}

.btn {
  cursor: pointer;
  border: 3px solid var(--accent);
  background: var(--accent);
  color: var(--white) !important;
  font-size: 18px;
  padding: 5px 20px;
  border-radius: 200px;
  text-decoration: none;
  transition: var(--trans);
  width: fit-content;

  &:hover {
    background: transparent;
    color: var(--accent) !important;
  }
}
.btnz {
  cursor: pointer;
  border: 3px solid var(--accent);
  background: var(--accent);
  color: var(--white) !important;
  font-size: 18px;
  padding: 5px 20px;
  border-radius: 200px;
  text-decoration: none;
  transition: var(--trans);
  width: fit-content;

  &:hover {
    background: transparent;
    color: var(--accent) !important;
  }
}

@media (max-width: 500px) 
{
  .btnz {
    font-size: 14px !important;
    padding: 5px 15px !important;

  } 
}

a {
  text-decoration: none;
}

.test123 {
  .sec-left .img-box {
    background-size: contain !important;
  }
}

.test1234 {
  .sec-left .img-box {
    background-position: left !important;
  }
}

@media only screen and (max-width: 1000px) {
  .test1234 .sec-left .img-box {
    // background-size: cover !important;
    height: auto !important;
    width: 100% !important;
    aspect-ratio: 3/4 !important;
  }
}

@media only screen and (max-width: 1000px) {
  .test123 .sec-left .img-box {
    // background-size: cover !important;
    height: auto !important;
    width: 100% !important;
    aspect-ratio: 3/4 !important;
  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    display: block !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
}

.swiper-button-next:after {
  font-size: 16px !important;
}

.swiper-button-prev:after {
  font-size: 16px !important;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(192, 191, 191);
  color: var(--accent);
  font-size: 16px;
  border-radius: 200px;
  height: 30px;
  width: 30px;
  display: none;
}

.gap {
  width: 100%;
  height: 30px;
}

.active {
  color: green;
}

/* Change the selection background color and text color */
::selection {
  background-color: var(--accent);
  /* Background color */
  color: var(--white);
  /* Text color */
}

/* For Firefox */
::-moz-selection {
  background-color: var(--accent);
  /* Background color */
  color: var(--white);
  /* Text color */
}

@media only screen and (max-width: 500px) {
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: -6px !important;
  }
}

.iframe-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #fff !important; 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; 
}

.iframe-container {
  width: 100vw;
  height: 100vh;
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.close-iframe {
  position: absolute;
  left: 65px;
  bottom: 85px; 
  padding: 25px 65px; 
  margin-bottom: 20p; 
  background-color: var(--accent);
  color: var(--white);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 18px;
  font-weight: 600;
  
}

.close-iframe:hover {
  background-color: var(--white); /* Your accent color */
  color: var(--accent);
  border: 2px solid var(--accent);
}

@media only screen and (max-width:1250px){
  .close-iframe {
    position: absolute;
    left: 0% !important;
    bottom: 5% !important;
    padding: 25px 40px !important;  
    font-size: 16px !important;
    margin-left: 15px;
    
  }

}

@media only screen and (max-width:500px){
  .close-iframe {
    position: absolute;
    left: 0% !important;
    bottom: 5% !important;
    padding: 15px 40px !important;  
    font-size: 16px !important;
    margin-left: 15px;
    
  }

}


.complainId{
  height: 100vh;
  .complainId-cont{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    padding: 20px;

    h2{
      color: var(--black);
      text-transform: lowercase;
      strong{
        color: var(--accent);
        font-size: 120%;
        text-transform:uppercase ;
      }
    }

    span{
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 40px;
      color: var(--grey);
      background: var(--accent);
      border-radius: 100px;
      padding: 20px;
    }
  }
}