.track-parent1 {
    min-height: calc(100svh - 60px);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: var(--grey);

    .track-container {
        display: flex;
        justify-content: center;
        align-items: center;

        .query-container-right {
            width: 100%;
            padding: 50px 0px;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .error-message {
                color: red !important;
                padding-top: 10px;
                font-weight: 400;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
            }

            .complaint-number-form {
                display: flex;
                align-items: center;
                gap: var(--gap);

                input {
                    padding: 15px 20px;
                    border-radius: calc(var(--rad) / 2);
                    border: none;
                    outline: none;
                    border: 1px solid transparent;
                    transition: var(--trans);
                    background: rgb(255, 255, 255);
                    width: 450px;

                    &:focus {
                        border: 1px solid var(--accent);
                    }
                }
            }

        }
    }

    .complainStatus {
        min-height: 100vh;


        .complainStatus-cont {
            padding: 50px 70px 0px 50px;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            h1 {
                font-size: 24px;
                text-transform: capitalize;
                font-weight: 700;
                margin-bottom: 10px;
            }

            h4 {
                text-transform: capitalize;
                margin-bottom: 30px;
            }

            .progress {
                width: 100%;
                height: 5px;
                background: rgb(199, 199, 199);
                border-radius: 100px;
                position: relative;
                transition: all 2s ease-out;

                &::after {
                    display: block;
                    content: "";
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    height: 100%;
                    width: 0%;
                    background: rgb(1, 145, 8);
                    transition: all 2s ease-out;
                }

                &.zero {
                    &::after {
                        width: 0%;
                    }
                }

                &.fifty {
                    &::after {
                        width: 50%;
                    }
                }

                &.hundred {
                    &::after {
                        width: 100%;
                    }
                }

                &.cancelled {
                    &::after {
                        width: 100%;
                        background: red;
                    }

                    .dot3 {
                        left: 100%;

                        &::after {
                            content: "Rejected";
                        }
                    }
                }

                .dot {
                    width: 20px;
                    height: 20px;
                    background: var(--accent);
                    border-radius: 100px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    z-index: 10;
                    border: 2px solid var(--white);
                    transition: all 2s ease-out;

                    &::after {
                        display: block;
                        position: absolute;
                        top: 20px;
                        transform: translateX(-50%);
                        left: 50%;
                        font-weight: 300;
                        color: var(--text);
                        transition: all 2s ease-out;
                        opacity: 0.5;
                    }

                    &.active {
                        outline: 4px solid var(--accent);

                        &::after {
                            font-weight: 500;
                            color: var(--accent);
                            opacity: 1;
                        }
                    }
                }

                .dot1 {
                    left: 0px;

                    &::after {
                        content: "Pending";
                    }
                }

                .dot2 {
                    left: 50%;

                    &::after {
                        content: "In Progress";
                        white-space: nowrap;
                    }
                }

                .dot3 {
                    left: 100%;

                    &::after {
                        content: "Completed";
                    }
                }
            }

            // p {
            //     margin-top: 60px;
            //     margin-bottom: 30px;
            //     text-align: center;
            // }

            .space {
                margin-bottom: 50px;
            }
        }

        .error-message {
            color: red !important;
            padding-top: 10px;
            font-weight: 400;
        }

        .complaint-data-container {
            display: flex;
            flex-direction: column;
            box-shadow: var(--shadow);
            align-items: center;
            justify-content: center;
            width: 800px;
            // margin: 50px auto auto auto;
            margin-top: 100px !important;
            padding: 20px;
            border-radius: 10px;
            margin-left: auto;
            margin-right: auto;

            .complaint-data-box {
                width: 100%;

                .first-row {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 20px;

                    .complaint-data {
                        color: black;
                        font-size: 18px;
                        font-weight: 400;
                        display: flex;
                        gap: 5px;
                        margin-bottom: 15px !important;

                        .d-span {
                            color: var(--accent);
                            font-size: 20px;
                            font-weight: 600;
                        }
                    }
                }

                .second-row {
                    display: flex;
                    flex-direction: column;
                    justify-content: start;
                    align-items: start;
                    gap: 10px;
                    width: 100%;

                    .complaint-feedback {
                        margin: 0;
                        font-size: 20px;

                        .feedback-p {
                            padding: 0 !important;
                            margin: 0 !important;

                        }
                    }
                }
            }
        }

        .new-cont {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 50px !important;
        }
    }
}


@media only screen and (max-width:800px) {
    .complaint-data-container {
        width: 90% !important;

        .complaint-data-box {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;

            .first-row {
                flex-direction: column;
                align-items: flex-start !important;
                gap: 10px;

                .second-row {
                    .feedback-p {
                        text-align: start !important;
                    }
                }
            }
        }
    }

}


@media only screen and (max-width:500px) {
    .complaint-data-container {
        width: 100% !important;

        .complaint-data-box {
            width: 100%;

            .first-row {
                width: 100%;
                display: flex;
                align-items: flex-start !important;
                justify-content: space-between;

                .complaint-data {
                    font-size: 16px !important;
                    font-weight: 300;

                    .d-span {
                        font-size: 18px !important;
                        font-weight: 600;
                    }
                }
            }

            .second-row {
                display: flex;
                flex-direction: column;
                justify-content: start;
                align-items: start;
                gap: 10px;
                width: 100%;

                .complaint-feedback {
                    margin: 0;
                    font-size: 16px !important;

                    .feedback-p {
                        text-align: start !important;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:700px) {
    .track-parent1 {
        width: 100%;
        .track-container {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            .query-container-right {
                width: 100%;
                display: flex;
                align-items: center;
                .complaint-number-form {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 10px;
                    input {
                        padding: 15px 20px;
                        width: 60%;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width:450px) {
    .track-parent1 {
         .track-container {
            
            .query-container-right {
                .track-hed{
                    font-size: 20px !important;
                    margin-bottom: 20px;
                   }
                .complaint-number-form {
                   flex-direction: column; 
                   gap: 20px;

                  
                    input {
                        
                    }
                }
            }
        }
    }
}