.scroll-top-btn {
  position: fixed;
  bottom: 120px;
  right: 9px;
  background-color:transparent ;
  color: var(--accent);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;

  cursor: pointer;
  opacity: 0;
  font-size: 30px;
  transition: opacity 0.3s ease-in-out;
  z-index: 9999; 
}

.scroll-top-btn.show {
  opacity: 1;
}

.scroll-top-btn.hide {
  opacity: 0;
}