.query-parent {
    // height: calc(100vh - 60px);
    padding: 30px 10px;

    .query-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: calc(var(--gap)* 2);

        .query-container-box {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: calc(var(--gap)* 2);

            .query-container-left {
                width: 70%;

                .query-form-box {
                    width: 100%;
                    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.17);
                    // padding: x;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: calc(var(--gap)* 2);
                    border-radius: 10px;


                    .form-title {
                        align-items: center;
                        justify-content: center;

                        font-size: 30px;
                        font-weight: 700;
                    }

                    .query-form {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        justify-content: center;
                        align-items: center;
                        gap: 20px;


                        .form-row {
                            display: flex;
                            width: 100%;
                            gap: 20px !important;
                            justify-content: center;
                            align-items: center;

                            input,
                            textarea {
                                padding: 15px 20px;
                                border-radius: calc(var(--rad) / 2);
                                border: none;
                                outline: none;
                                border: 1px solid transparent;
                                transition: var(--trans);
                                background: rgb(245, 245, 245);
                                width: 100%;

                                &:focus {
                                    border: 1px solid var(--accent);
                                }
                            }



                        }

                    }
                }
            }


        }
    }


}

//  {/* ---container-right----- */}
.track-parent {
    background-color: var(--grey);

    .track-container {
        display: flex;
        justify-content: center;
        align-items: center;

        .query-container-right {
            width: 100%;
            padding: 50px 0px;
            gap: var(--gap);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .complaint-number-form {
                display: flex;
                align-items: center;
                gap: var(--gap);

                input {
                    padding: 15px 20px;
                    border-radius: calc(var(--rad) / 2);
                    border: none;
                    outline: none;
                    border: 1px solid transparent;
                    transition: var(--trans);
                    background: rgb(255, 255, 255);
                    width: 450px;

                    &:focus {
                        border: 1px solid var(--accent);
                    }
                }
            }

        }
    }
}

@media only screen and (max-width:700px) {
    .track-parent {
        width: 100%;

        .track-container {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .query-container-right {
                width: 100%;
                display: flex;
                align-items: center;

                .complaint-number-form {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 10px;

                    input {
                        padding: 15px 20px;
                        width: 60%;
                    }
                }
            }
        }
    }
}


@media only screen and (max-width:450px) {
    .query-parent {

        .query-container {
            .query-container-left {
                width: 100% !important;

                .query-form-box {
                    padding: 30px 10px;
                }
            }
        }
    }
}


// input[type="number"]::-webkit-inner-spin-button,
// input[type="number"]::-webkit-outer-spin-button {
//   -webkit-appearance: none;
//   margin: 0;
// }

// input[type="number"] {
//   -moz-appearance: textfield; /* For Firefox */
// }