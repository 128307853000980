.complainId {
  .complainId-cont {
    .h2-copy {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      .copy-button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        cursor: pointer;
      }
    }
  }
}
