.blog-template-parent {
  margin-top: 70px;
  padding: 50px 10px;

  .blog-template-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;

    .blog-template-top {
      width: 100%;
      height: 100%;
      display: flex;
      gap: 15px;

      .template-top-left {
        width: 70%;
        // border: 1px solid yellow;
        border-radius: 10px;
        overflow: hidden;

        .template-blog-img {
          width: 100%;
          height: 400px;
          position: relative;
          

          .overlay {
            position: absolute;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.5);
            height: 100%;
            width: 100%;
            backdrop-filter: blur(4px);
          }

          .blo-img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            position: relative;
            z-index: 1;
          }
        }
      }

      .template-top-right {
        width: 30%;
        display: flex;
        gap: 10px;
        flex-direction: column;
        justify-content: space-evenly;
        border-radius: 10px;
        background-color: #efefef;
        padding: 15px;

        .catergory {
          background-color: white;
          display: flex;
          border-radius: 10px;
          flex-direction: column;
          padding: 10px;
          gap: 10px;

          span {
            color: var(--accent);
          }

          .cate {
            text-align: start;
          }
        }

        .publish-date {
          background-color: white;
          display: flex;
          border-radius: 10px;
          flex-direction: column;
          padding: 10px;
          gap: 10px;

          span {
            color: var(--accent);
          }

          .date {
            text-align: start;
          }
        }
      }
    }

    .blog-template-bottom {
      width: 100%;
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      justify-content: center;
      gap: 10px;
      overflow: hidden;
      background-color: #efefef;
      padding: 20px;
    }
  }
}

@media only screen and (max-width: 900px) {
  .blog-template-parent {
    .blog-template-container {
      .blog-template-top {
        flex-direction: column;

        .template-top-left {
          width: 100%;
        }

        .template-top-right {
          width: 100%;

          .catergory {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
          }

          .publish-date {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
          }
        }
      }
    }
  }
}
